export function getPopularProblems() {
  const { $api } = useNuxtApp();
  return $api<Array<SeoTitles>>('/grouped-popular-repair-services/', {
    method: 'GET',
  });
}

export function getArticle(params: SeoArticleParams) {
  const { $api } = useNuxtApp();
  return $api<SeoArticle>('/seo-article/', {
    method: 'GET',
    params: params,
  });
}
